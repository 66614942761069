<template lang="pug">
  el-table(
    :data="tableData_"
    style="width: 100%"
    :show-header="isFirst"
  )
    el-table-column(
      prop="level"
      label="Уровень объекта"
    )
    el-table-column(
      prop="quantity"
      label="Количество актуальных объектов на дату обновления"
    )
    el-table-column(
      prop="changes"
      label="Изменение количества объектов за неделю"
    )
</template>

<script>
export default {
  name: 'a-table',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    isFirst: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tableData_() {
      return this.tableData.map(item => {
        return {
          level: (item.level !== null) ? item.level : 'Нет значения',
          quantity: (item.quantity !== null) ? item.quantity : 'Нет значения',
          changes: (item.changes !== null) ? item.changes : 'Нет значения',
        }
      })
    }
  }
}
</script>