<template lang="pug">
  .fb
    .fb__container.container

      //- Титульная линия страницы
      .fb__top-line
        .fb__title-container
          h1.fb__title {{ title }}
          p.fb__subtitle(v-if="showDate") Последнее обновление: {{ lastDate }}
      
      //- Таблица
      .fb__content
        a-preload(
          v-show="showPreload"
        )
        .home__table-container(v-for="(item, $index) in tableData" :key="$index")
          h2.home__table-title {{ $index }}
          a-table.home__table(
            :tableData="item"
            :isFirst="$index === 'Адресные объекты' ? true : false"
          )
</template>

<script>
import ATable from '../../../components/a-table/a-table'
import APreload from '../../../components/a-preload/a-preload'

export default {
  name: 'Statistics',

  components: {
    ATable,
    APreload
  },

  data() {
    return {
      title: 'Статистика',
      showPreload: false,
      showDate: false,
    }
  },

  computed: {
    tableData() {
      return this.$store.getters['statistics/tableData']
    },
    lastDate() {
      return this.$store.getters['statistics/lastDate']
    }
  },

  mounted() {
    this.getStatistics()
  },

  methods: {
    async getStatistics() {
      this.showPreload = true
      this.showDate = false
      await this.$store.dispatch('statistics/getStatistics')
      this.showPreload = false
      this.showDate = true
    }
  }
}
</script>